<template>
<!--  <div id="nav">-->
<!--    <router-link to="/">Home</router-link>-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </div>-->
  <router-view/>
</template>

<script>
import { onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { firebaseAuth } from './main';

export default {

  setup(){

    const router = useRouter();
    const route = useRoute();

    onBeforeMount(() => {

      firebaseAuth.onAuthStateChanged((user) => {

        if(!user){
          setTimeout(function() { router.replace('/login'); }, 2000);
        } else if (route.path === '/login' || route.path === '/'){
          setTimeout(function() { router.replace('/home'); }, 2000);
        }

      }, );

    });

  }

}

</script>

<style>

body{
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #eeeeee;
  background-color: #00175a;
  min-height: 100vh;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-highlight: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

h1, h2, h3 {

  margin: 0;

}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.page{
  padding: 20px;
}
</style>
