import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAKuMUUafNxb32aQ4EN183c1IXacdwiGRs",
    authDomain: "amexgnafrance.firebaseapp.com",
    projectId: "amexgnafrance",
    storageBucket: "amexgnafrance.appspot.com",
    messagingSenderId: "927301503749",
    appId: "1:927301503749:web:e9eec26c767c45b67ec56c",
    measurementId: "G-Y0G3TPSJDV"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export const listRef = ref(firebaseStorage, '/');
export const firestore = getFirestore(firebaseApp);

createApp(App).use(store).use(router).mount('#app');
