<template>
  <div class="splash">
    <div class="welcome">
      <img alt="Vue logo" class="logo" src="../assets/img/axp-logo.svg">
      <h2>Global New Account</h2>
    </div>
    <h4 class="note-connection">Checking your connection...</h4>
  </div>
</template>



<style>

.logo{
  max-width: 150px;
}

.splash{
  height: 100vh;
  position: relative;
}

.welcome{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note-connection{
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 50px;
  display: inline;
  width: fit-content;
  transform: translate(-50%, 0);
}

</style>
